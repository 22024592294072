import type { Schemas } from '#shopware';
import type { ShownProducts } from '~/types/tracking/shownProducts';
import type { Ecommerce } from '~/types/tracking/ecommerce';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import { useProductPrices } from '~/composables/useProductPrices';
import type { ProductDownload } from '~/types/models/product/downloads';
import type {
    AdditionalLineItems,
    ExtendedLineItem,
    LineItemsEcommerceOptions,
    OrderLineItemsEcommerceOptions,
} from '~/types/tracking/additionalLineItems';
import type { AhProduct } from '~/types/models/product/product';
import type { ImageSeen } from '~/types/tracking/imagesSeen';
import { breakpointsTailwind } from '@vueuse/core';
import type { AreaType } from '~/types/tracking/areaType';
import type { AlgoliaProductTrackingType } from '~/types/tracking/AlgoliaProductTrackingType';
import type { AhCartItem } from '~/types/models/cart';
import type { OrderLineItem } from '@shopware-pwa/types';

const imagesSeen = ref<ImageSeen[]>([]);
const imagesSeenModal = ref<ImageSeen[]>([]);

export function usePrepareForTracking() {
    const { sessionContext } = useSessionContext();
    const { isLoggedIn } = useUser();
    const { getPrice } = useProductPrices();
    const formatPrice = (price: number | undefined): number | string => {
        return price ? Number(price.toFixed(2)) : '';
    };

    const getPriceForTracking = (product: AhProduct | null): number | string => {
        const productPrice = getPrice(product?.productNumber);
        if (productPrice?.price) return formatPrice(productPrice.price);
        if (!isLoggedIn.value) return 'Login for prices';
        return 'Price not available';
    };

    const getLoginStatus = () => ({
        loggin_status: isLoggedIn.value,
    });

    // returns the price of a product or a string if the user is not logged in
    const getProductViewPrice = (product: AhProduct | null): { product_price: number | string } => {
        return {
            product_price: getPriceForTracking(product),
        };
    };

    const prepareDetailPageProductForTracking = (product: AhProduct): Ecommerce => {
        const productPrice = getPrice(product.productNumber);
        return {
            currency: sessionContext.value?.currency?.isoCode,
            value: formatPrice(productPrice?.price),
            items: [
                {
                    item_id: product.productNumber,
                    item_name: product.name,
                    price: getPriceForTracking(product),
                    currency: sessionContext.value?.currency?.isoCode,
                    item_brand: product.manufacturer?.name || product.manufacturer?.translated?.name,
                    item_category: product.seoCategory?.breadcrumb?.length
                        ? product.seoCategory.breadcrumb[product.seoCategory.breadcrumb.length - 1]
                        : '',
                    item_category_id: '',
                    item_variant: product.extensions?.attributes?.subtitle,
                },
            ],
        };
    };

    const prepareAdditionalLineItemsForTracking = (options: AdditionalLineItems) => {
        const { totalPrice, tax, shippingCosts, shippingTier, paymentType, transactionId, coupon, discount } = options;

        return {
            currency: sessionContext.value?.currency?.isoCode,
            value: formatPrice(totalPrice),
            tax: formatPrice(tax),
            shipping: shippingCosts,
            shipping_tier: shippingTier,
            payment_type: paymentType,
            transaction_id: transactionId,
            coupon: coupon,
            discount: discount,
        };
    };

    const prepareLineItemsForTracking = (lineItem: ExtendedLineItem[]) => {
        const trackShownProducts: ShownProducts[] = [];

        lineItem.forEach((cartItem: ExtendedLineItem) => {
            const trackingProductInfos = {
                item_id: (cartItem as AhCartItem).payload?.productNumber,
                item_name: cartItem.label,
                price: formatPrice(cartItem?.price?.unitPrice),
                currency: sessionContext.value?.currency?.isoCode,
                item_brand: cartItem.payload?.manufacturer,
                item_category_id: '',
                quantity: cartItem.quantity,
            };
            trackShownProducts.push(trackingProductInfos);
        });
        return { items: trackShownProducts };
    };

    const prepareLineItemsEcommerceForTracking = (options: LineItemsEcommerceOptions) => {
        const { lineItem, totalPrice, tax, shippingCosts, shippingTier, paymentType, transactionId, coupon, discount } =
            options;

        return {
            ...prepareAdditionalLineItemsForTracking({
                totalPrice,
                tax,
                shippingCosts,
                shippingTier,
                paymentType,
                transactionId,
                coupon,
                discount,
            }),
            ...prepareLineItemsForTracking(lineItem),
        };
    };

    const prepareOrderLineItemsEcommerceForTracking = (options: OrderLineItemsEcommerceOptions) => {
        const {
            lineItems,
            totalPrice,
            tax,
            shippingCosts,
            shippingTier,
            paymentType,
            transactionId,
            coupon,
            discount,
        } = options;

        return {
            ...prepareAdditionalLineItemsForTracking({
                totalPrice,
                tax,
                shippingCosts,
                shippingTier,
                paymentType,
                transactionId,
                coupon,
                discount,
            }),
            ...prepareCheckoutOrderLineItemsForTracking(lineItems),
        };
    };

    const prepareCheckoutOrderLineItemsForTracking = (lineItems: OrderLineItem[]) => {
        const trackShownProducts: ShownProducts[] = [];

        lineItems.forEach((lineItem) => {
            const trackingProductInfos = {
                // @ts-ignore
                item_id: lineItem.payload?.productNumber,
                item_name: lineItem.label,
                price: formatPrice(lineItem?.unitPrice),
                currency: sessionContext.value?.currency?.isoCode,
                // @ts-ignore
                item_brand: lineItem.payload?.manufacturer,
                item_category_id: '',
                quantity: lineItem.quantity,
            };
            trackShownProducts.push(trackingProductInfos);
        });
        return { items: trackShownProducts };
    };

    const prepareOrderLineItemsForTracking = (
        lineItems: Schemas['AhOrderPosition'][],
        listName: string,
        index?: number,
    ) => {
        const trackShownProducts: ShownProducts[] = [];

        lineItems.forEach((item) => {
            const trackingProductInfos = {
                item_id: item.articlenumber,
                item_name: item.name,
                price: formatPrice(item.price),
                currency: sessionContext.value?.currency?.isoCode,
                item_brand: item.supplier,
                item_category: '',
                item_category_id: '',
                item_list_name: listName,
                quantity: item.quantity,
                index: index,
            };
            trackShownProducts.push(trackingProductInfos);
        });
        return { items: trackShownProducts };
    };

    const prepareAhWishlistProductForTracking = (
        ahWishlistProduct: Schemas['AhWishlistProduct'][],
        indexInList?: number,
    ) => {
        const trackShownProducts: ShownProducts[] = [];

        ahWishlistProduct.forEach((product: Schemas['AhWishlistProduct']) => {
            const trackingProductInfos = {
                item_id: product.product?.productNumber,
                item_name: product.product?.name,
                price: getPriceForTracking(product.product),
                currency: sessionContext.value?.currency?.isoCode,
                item_brand: product.product?.manufacturer || '',
                item_category: product.product.breadcrumb?.length
                    ? product.product.breadcrumb[product.product.breadcrumb.length - 1].categoryName
                    : '',
                item_category_id: '',
                item_list_name: 'from a watchlist',
                quantity: product.quantity,
                index: indexInList,
            };
            trackShownProducts.push(trackingProductInfos);
        });
        return { items: trackShownProducts };
    };

    const prepareAlgoliaProductsForTracking = (
        itemList: AlgoliaProduct[] | AlgoliaProductTrackingType[],
        indexInList?: number,
        itemListName?: string,
    ): Ecommerce => {
        const trackShownProducts: ShownProducts[] = [];

        itemList.forEach((result) => {
            const trackingProductInfos = {
                item_id: result.productNumber,
                item_name: result.name,
                price: getPriceForTracking(result),
                currency: sessionContext.value?.currency?.isoCode,
                item_brand: result.manufacturer,
                item_category: result.breadcrumb?.length
                    ? result.breadcrumb[result.breadcrumb.length - 1].categoryName
                    : '',
                item_category_id: '',
                item_list_name: itemListName,
                item_variant: result.description_short,
                index: result.originalIndex >= 0 ? result.originalIndex : indexInList,
            };
            trackShownProducts.push(trackingProductInfos);
        });
        return {
            items: trackShownProducts,
        };
    };

    const prepareTypeProductForTracking = (product: Schemas['Product']): Ecommerce => {
        return {
            items: [
                {
                    item_id: product.productNumber,
                    item_name: product.name,
                    price: getPriceForTracking(product as AhProduct),
                    currency: sessionContext.value?.currency?.isoCode,
                    item_brand: product.manufacturer?.name || product.manufacturer?.translated?.name,
                    item_category: product.seoCategory?.breadcrumb?.length
                        ? product.seoCategory.breadcrumb[product.seoCategory.breadcrumb.length - 1]
                        : '',
                    item_category_id: '',
                },
            ],
        };
    };

    const prepareProductDownloadsForTracking = (
        productDownloads: ProductDownload[],
        checkedProductDownloads: string[],
        type?: string,
    ): ProductDownload[] => {
        if (type === 'only-selected-files') {
            return productDownloads?.filter((productDownload) => {
                return checkedProductDownloads.includes(productDownload.id);
            });
        }

        return [...productDownloads];
    };

    const getProductsCategories = (
        productDownloads: ProductDownload[],
        checkedProductDownloads: string[],
        type?: string,
    ) => {
        const productCategories: string[] = [];

        const productsToTrack: ProductDownload[] = prepareProductDownloadsForTracking(
            productDownloads,
            checkedProductDownloads,
            type,
        );

        productsToTrack?.forEach((product) => {
            productCategories.push(product.category);
        });

        return productCategories.sort().join(' - ');
    };

    const addImagesSeen = (area: AreaType, mediaGallery: any[], activeIndex: number) => {
        if (activeIndex >= mediaGallery.length) return;
        const watchedImage = mediaGallery?.[activeIndex]?.media;
        const fileName = extractFilenameFromUrl(watchedImage?.url);

        const imageSeen: ImageSeen = {
            id: watchedImage?.extensions?.attributes?.mediaId,
            filename: fileName || '',
        };

        const imageSeenType = getImageSeenType(area);
        const alreadySeen = imageSeenType.some((image) => image.id === imageSeen.id);
        if (!alreadySeen) {
            imageSeenType.push(imageSeen);
        }
    };

    const getImageSeenType = (area: AreaType): ImageSeen[] => {
        let imageSeenType: ImageSeen[] = [];
        switch (area) {
            case 'Page':
                imageSeenType = imagesSeen.value;
                break;
            case 'Modal':
                imageSeenType = imagesSeenModal.value;
                break;
        }
        return imageSeenType;
    };
    const getFilenames = (area: AreaType): string[] => {
        return getImageSeenType(area).map((image) => image.filename);
    };

    const preparePdpSliderImagesForTracking = (
        area: AreaType,
        product: Schemas['Product'] | null,
        totalImagesCount: number,
        imagesNames: string[],
    ) => {
        return {
            select_content: 'Image viewed in ' + area,
            product_name: product?.name,
            images_seen: area === 'Page' ? imagesSeen.value.length : imagesSeenModal.value.length, //count
            total_images: totalImagesCount,
            image_order: imagesNames,
        };
    };
    const prepareZoomedImageForTracking = (
        mediaGallery: any[],
        product: Schemas['Product'] | null,
        index: number | undefined,
    ) => {
        if (index === undefined || index >= mediaGallery.length) return {};

        const watchedImage = mediaGallery?.[index]?.media;
        const fileName = extractFilenameFromUrl(watchedImage?.url);

        return {
            select_content: 'Image zoomed',
            product: product?.name + ' - ' + fileName,
        };
    };

    const categoryDataMap = new Map<string, { ids: Set<string>; images: AlgoliaProductTrackingType[] }>();

    const addSliderImages = (
        products: AlgoliaProduct[],
        sliderIndex: number,
        categoryType?: string,
    ): AlgoliaProduct[] => {
        if (!categoryType) return [];

        if (!categoryDataMap.has(categoryType)) {
            categoryDataMap.set(categoryType, { ids: new Set<string>(), images: [] });
        }

        const categoryData = categoryDataMap.get(categoryType)!;
        const seenProductIds = categoryData.ids;
        const seenImages = categoryData.images;

        let numImagesToAdd: number;

        switch (true) {
            case window.innerWidth < breakpointsTailwind.lg:
                numImagesToAdd = 2;
                break;
            case window.innerWidth <= breakpointsTailwind.xl:
                numImagesToAdd = 4;
                break;
            default:
                numImagesToAdd = 5;
                break;
        }

        for (let i = sliderIndex; i < sliderIndex + numImagesToAdd && i < products.length; i++) {
            const product = products[i];
            if (product && !seenProductIds.has(product.productId)) {
                seenProductIds.add(product.productId);
                seenImages.push({
                    ...product,
                    originalIndex: i,
                });
            }
        }

        return seenImages;
    };

    const findDiscountCodes = (lineItems: ExtendedLineItem[]) => {
        const item = lineItems?.find((item) => item.payload?.code);
        if (!item) return;
        const discountValue =
            typeof item.payload.value === 'number' ? item.payload.value.toFixed(2) : item.payload.value;
        return { code: item.payload.code, value: discountValue, totalReduction: item.totalPrice?.toFixed(2) };
    };

    const removeUndefinedProperties = (ecommerce: Ecommerce): Ecommerce => {
        return <Ecommerce>Object.fromEntries(Object.entries(ecommerce).filter(([_, value]) => value !== undefined));
    };
    const filterProductItems = (cartItems: Schemas['LineItem'][]): Schemas['LineItem'][] => {
        return cartItems.filter((item: Schemas['LineItem']) => item.type === 'product');
    };
    const filterOrderItemsForProductItems = (lineItems: OrderLineItem[]): OrderLineItem[] => {
        return lineItems.filter((item: OrderLineItem) => item.type === 'product');
    };

    return {
        imagesSeen,
        imagesSeenModal,
        prepareLineItemsForTracking,
        filterOrderItemsForProductItems,
        prepareAhWishlistProductForTracking,
        getLoginStatus,
        getProductViewPrice,
        prepareAlgoliaProductsForTracking,
        prepareLineItemsEcommerceForTracking,
        prepareOrderLineItemsEcommerceForTracking,
        prepareProductDownloadsForTracking,
        getProductsCategories,
        prepareTypeProductForTracking,
        prepareDetailPageProductForTracking,
        addImagesSeen,
        getFilenames,
        preparePdpSliderImagesForTracking,
        prepareZoomedImageForTracking,
        addSliderImages,
        prepareOrderLineItemsForTracking,
        findDiscountCodes,
        removeUndefinedProperties,
        filterProductItems,
    };
}
